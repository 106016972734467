@import "fonts.css";
@import "appcolors";
@import "mixins";
@import "pages";
.App{
  background-color: $appbgcolor ;
  font-family: "Roboto",sans-serif;


}
.homecontent{
  text-align: center;
}

#root{
  width:100%;
  overflow-x: hidden;
}
.innerctabutton{
background-color: var(--ctabutton-background-color);
  color:var(--ctabutton-textcolor);
  text-transform: uppercase;
  text-decoration: none;
  @include fontSize(18);
  @include lineHeight(20);
  width: fit-content;
  text-align: center;
  padding:17px 35px;
  display: block;
border:none!important;
  border-radius: 5px;
  font-weight: bold;
  @include setTransition();
  &:hover{
    background-color:var(--ctabutton-background-hover-color);
  }
  @include tablet-max {
    position: relative;
    top: 50px;
  }
}