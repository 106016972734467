$b:#000000;
$w:#ffffff;
$orange: #FF7816;
$appbgcolor: #ffffff;
/*Header*/
$headerBackgroundColor: #B1BAE0;

/*Anzeigen Farben*/
$listbg: #DBC5A5;
$libghover: #E5D9C7;
$labelColor: #000000;

/*Schriftfarben*/
$mainheadlinecolor: #004f9f;
$secondHeadlinecolor: #575D64;
$thirdHeadLinecolor:#20262C;

$joblistborderdcolor: #DE007E;
$joblisthovercolor: rgba(222,0,126,.3);
$joblistlinkcolor: $b;
/*Footer*/
$footerBackgroundColor: #004F9F;
$footerlinkcolor:#484a4f;
$inputBordderColor:#c5c8d3;



:root{
  --content-uns-background: #004f9f;
  --content-uns-sublinecolor: $w;
  --footer-background-color: #B1BAE0;
  --footer-linkcolor: #484a4f;
  --job-detail-h5color:#20262c;
  --job-detail-h2color:#0446BE;

  --job-detail-background-color: $w;
  --job-detail-imageboxbackground-color: $w;
  --job-location-backgroundcolor:#B1BAE0;
  --job-location--textcolor:#004f9f;
  --jobduration-mainheadline-color: #000000;
  --jobdetailheadline-mainheadline-color: #575D64;
  --jobtodos-headline-color:#004f9f;
  --joboffercontent-backgroundcolor:  #F9F6F2;
  --joboffercontent-headlinecolor:$b;
  --joboffercontent-contentcolor:$b;
  --joboffercontent-ctabackgroundcolor:#DE007E;
  --joboffercontent-ctabackhovergroundcolor:lighten(#DE007E,5%);
  --joboffercontent-ctacontentcolor:$w;

  --ctabutton-background-color:#DE007E;
--ctabutton-background-hover-color:lighten(#DE007E,5%);
--ctabutton-textcolor:$w;

  --form-contentcolor: #484a4f;
  --form-answer-backgroundcolor:#004F9F;
  --form-answer-backgroundhovercolor:#0B6BCD;
  --form-answer-textcolor:$w;
  --form-label-color:$b;
  --form-border-color:#c5cbd3;
  --form-headline-color:#484a4f;
  --form-dse-linkcolor: $b;

}
